import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import './index.css';
import * as PIXI from 'pixi.js';
import * as spine from 'pixi-spine';
import * as particles from 'pixi-particles';
import { getSpineRuntime } from './spineRuntime';

window.PIXI = PIXI;
window.PIXI.spine = spine;
window.PIXI.particles = particles;

(async function init() {
    window.PIXI.spine.core = await getSpineRuntime();
    const App = lazy(() => import('./App'));
    const Page = () => {
        return (
            <div>
                <Suspense fallback={<LinearProgress />}>
                    <App />
                </Suspense>
            </div>
        );
    };
    ReactDOM.render(<Page />, document.getElementById('root'));
})();
