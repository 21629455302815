const defaultVersion = '3.8';

export function getSpineVersion() {
    const urlParams = new URLSearchParams(window.location.search);
    const spineParam = urlParams.get('spine');
    const version = getSpineVersion.supportedVersions.includes(spineParam)
        ? spineParam
        : defaultVersion;
    getSpineVersion.current = version;
    return version;
}

getSpineVersion.current = defaultVersion;
getSpineVersion.supportedVersions = [defaultVersion, '3.7', '4.0', '4.1'];

export async function getSpineRuntime() {
    const version = getSpineVersion();
    if (version === defaultVersion) {
        return import('@pixi-spine/runtime-3.8');
    }
    if (version === '4.0') {
        return import('@pixi-spine/runtime-4.0');
    }
    if (version === '4.1') {
        return import('@pixi-spine/runtime-4.1');
    }
    return import('@pixi-spine/runtime-3.7');
}
